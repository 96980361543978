import React from "react";
import { Routes, Route } from "react-router-dom";
import { router } from "./Routes";

const Index = (props) => {
  const routeComponents = router.map((item, key) => (
    <Route
      path={item.path}
      element={
        item.routeType ? (
          <item.routeType>
            <item.element {...props} />
          </item.routeType>
        ) : (
          <item.element {...props} />
        )
      }
      key={key}
    />
  ));

  return <Routes>{routeComponents}</Routes>;
};

export default Index;
