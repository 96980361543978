import { MenuItem, Select, FormHelperText, FormControl } from '@mui/material'
import React, { useEffect, useLayoutEffect } from 'react'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

const SelectField = ({
  mt,
  handleChangeForSelect,
  value,
  menuData,
  disabled,
  error,
  errorMessage,
  def,
  name,
}) => {
    useLayoutEffect(() => {
    }, [def])
    
  return (
    <>
        <FormControl error={error} sx={{ width: '100%',
              maxWidth: '155px',
         }}>
          <Select
            variant="outlined"
            defaultValue={def}
            value={def}
            onChange={handleChangeForSelect}
            disabled={disabled}
            displayEmpty
            SelectDisplayProps={{
              style: {
                padding: 3,
                marginLeft: '10px',
                color: value === 'Select Program' ? '#757575' : '',
                textTransform: 'capitalize',

              },
            }}
            MenuProps={{
              style: {
                maxHeight: '300px',
                zIndex: 99999,
              },
              disableScrollLock: true,
            }}
            IconComponent={CustomDropdownIcon}
            inputProps={{
              style: {
                paddingTop: '8px',
                paddingBottom: '8px',
                height: '300px',
                textTransform: 'capitalize',
              },
            }}
            color="primary"
            sx={{
              ml:1,
              mr:2,
              minHeight: '50px',
              width: '100%',
              fontSize: '16px',
              fontWeight: '400',
              maxWidth: '150px',
              borderRadius: '5px',
              '& .MuiSelect-select:focus': {
                backgroundColor: 'transparent',
              },
              '&.MuiList-root': {
                height: '300px',
              maxWidth: '140px',
            },
              maxWidth: '140px',
              mt: mt,
            }}
          >
            {menuData?.map((item, index) => (
              <MenuItem
                key={index}
                sx={{
                  textTransform: 'capitalize',
                  '&.css-10nakn3-MuiModal-root-MuiPopover-root-MuiMenu-root': {
                    zIndex: 99999,
                  },
                }}
                value={item}
              >
                {item}
              </MenuItem>
            ))}
          </Select>
          {error && (
            <FormHelperText sx={{ '&.MuiFormHelperText-root': { ml: 0 } }}>
              {errorMessage}
            </FormHelperText>
          )}
        </FormControl>
    </>
  )
}

const CustomDropdownIcon = (props) => {
  return <ExpandMoreIcon color="#757575" {...props} />
}

export default SelectField