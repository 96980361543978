import React from 'react'
import { CSS } from './CSS';
import { Button } from '@mui/material';

const CustomButton = ({name,color,type,onclick,ml}) => {
  return (
    <Button sx={{
        textTransform: 'none', bgcolor: color, color: 'white', mt: 2,ml:ml, width: "100px", "&.MuiButton-root:hover": {
            bgcolor: color
        }}} type={type} onClick={onclick} >{name}</Button>
)
}

export default CustomButton