import { SET_AI_BID, SET_BID, SET_BIDS, SET_DASHBOARD, SET_LAST_BIDS, SET_LOADING, SET_LOGIN, SET_MONTHLY_AVG, SET_PROJECTS, SET_PROJECTS_ID, SET_USER_BY_ID, SET_WEEKLY_AVG } from "../ConstAction";
const initialState = {};
export const AdminData = (state = initialState, action) => {
    switch (action.type) {
    
    case SET_MONTHLY_AVG:
      return {
        ...state,
        monthly: action.response.data,
      };
      case SET_WEEKLY_AVG:
        return {
          ...state,
          weekly: action.response.data,
        };
        case SET_LAST_BIDS:
          return {
            ...state,
            bids: action.response.data,
          };
          case SET_USER_BY_ID:
          return {
            ...state,
            user: action.response.data,
          };
    default:
      return {...state};
  }
};
