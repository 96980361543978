import './App.css';
import CompDrawer from './Components/Drawer';
import Index from './routes'; // Adjust the path as needed
import { storage } from './Config/Storage';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const  App = () =>{
  const location = useLocation()
  const role = storage.fetch.user()
 useEffect(() => {
   
 }, [role])
 
  
  return (
    <div className="App">
      {/* Conditionally render Drawer component based on the pathname */}
      {role?.role_id===1&&location.pathname!=="/"?
      (
        <CompDrawer />
      ):
      (<Index />)
      }

      {/* Render the routing logic */}
    </div>
  );
}

export default App;
