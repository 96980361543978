import { GET_ADD_EDIT_USER, GET_DELETE_USER, GET_LAST_BIDS, GET_MONTHLY_AVG, GET_USER_BY_ID, GET_WEEKLY_AVG } from "../ConstAction";

export const MonthlyAvg = (data) => {
  return {
    type: GET_MONTHLY_AVG,
    data: data,
  };
};
export const WeeklyBids = (data) => {
  return {
    type: GET_WEEKLY_AVG,
    data: data,
  };
};
export const Getlast5Bids = (data) => {
  return {
    type: GET_LAST_BIDS,
    data: data,
  };
};
export const addeditUser = (data,navigate) => {
  return {
    type: GET_ADD_EDIT_USER,
    data: data,
    navigate: navigate,
  };
};
export const deleteuser = (data) => {
  return {
    type: GET_DELETE_USER,
    data: data,
  };
};
export const getUserbyid = (data) => {
  return {
    type: GET_USER_BY_ID,
    data: data,
  };
};