import { Api } from "../../Config/Requests";
export const getmonthlyavg = async (data) => {
  return Api.GetRequest("admin/average-bids-per-month", data);
};
export const getweeklybids = async (data) => {
  return Api.GetRequest("admin/average-bids-per-week", data);
};
export const getlast5bids = async (data) => {
  return Api.GetRequest("admin/getlastbids", data);
};
export const getaddedit = async (data) => {
  return Api.PostRequest("users/register", data);
};
export const requestdeleteuser = async (data) => {
  return Api.PostRequest("users/delete", data);
};
export const requestuserbyid = async (data) => {
  return Api.PostRequest("users/userbyid", data);
};
const AdminApiRequests = {
  getmonthlyavg,
  getweeklybids,
  getlast5bids,
  getaddedit,
  requestdeleteuser,
  requestuserbyid
};
export default AdminApiRequests;
