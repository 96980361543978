import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import annotationPlugin from 'chartjs-plugin-annotation';
import EditIcon from '@mui/icons-material/Edit';
import {
  Chart,
  CategoryScale,
  LinearScale,
  LineController,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { useDispatch, useSelector } from 'react-redux';
import { Getlast5Bids, MonthlyAvg, WeeklyBids } from '../../redux/Admin/AdminAction';
import { Grid, IconButton, Paper, styled, Table, TableBody, TableCell, tableCellClasses, TableContainer, TableHead, TableRow } from '@mui/material';
import { CSS } from '../../Components/CSS';
import { storage } from '../../Config/Storage';
import { Link } from 'react-router-dom';

// Register chart components
Chart.register(
  CategoryScale,
  LinearScale,
  LineController,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend,
  annotationPlugin
);

const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top',
    },
  },
  scales: {
    x: {
      type: 'category', // Categorical scale for months
    },
    y: {
      beginAtZero: true, // Start y-axis from zero
    },
  },
};
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const AdminDashboard = () => {
  const detail = useSelector((state) => state.AdminData) || {};
  const dispatch = useDispatch();
  const [data, setdata] = useState()
  const [data1, setdata1] = useState()
  const [rows, setRows] = useState()
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth() + 1; // getMonth() returns 0-11
  const currentYear = currentDate.getFullYear();
  const user = storage.fetch.user()
  // Fetch data on component mount
  useEffect(() => {
    dispatch(MonthlyAvg());
    dispatch(WeeklyBids());
    dispatch(Getlast5Bids());
  }, []);

  // Prepare the chart data based on the fetched details
  useEffect(() => {

    setdata({
      labels: Array.from({ length: currentMonth }, (_, i) => {
        const monthIndex = i + 1; // Convert 0-index to 1-index
        return new Date(currentYear, monthIndex - 1).toLocaleString('default', { month: 'long' });
      }),
      datasets: [
        {
          label: 'Monthly Bids',
          data: detail?.monthly
            ? detail.monthly.reduce((acc, item) => {
              acc[item.month - 1] = item.total_bids; // Place total bids at the correct month index
              return acc;
            }, new Array(12).fill(0)) // Initialize with zeroes for all months
            : new Array(12).fill(0), // Default to all zeroes if no data
          fill: false,
          backgroundColor: 'rgba(75,192,192,0.2)',
          borderColor: 'rgba(75,192,192,1)',
          pointBorderColor: 'rgba(75,192,192,1)',
          pointBackgroundColor: '#fff',
          pointHoverBackgroundColor: 'rgba(75,192,192,1)',
          pointHoverBorderColor: 'rgba(220,220,220,1)',
          pointRadius: 5,
          pointHoverRadius: 8,
        },
      ],
    })
    setdata1({
      labels: Array.from({ length: 7 }, (_, i) => {
        // Generate the date for each of the last 7 days
        const date = new Date();
        date.setDate(date.getDate() - (7 - i)); // Get the date for the last 7 days
        return date.toLocaleDateString('default', { weekday: 'long', day: 'numeric', month: 'short' }); // Format as 'Mon, 12 Sep'
      }),
      datasets: [
        {
          label: 'Weekly Bids',
          data: detail?.weekly
            ? detail.weekly.reduce((acc, item) => {
              // Assuming item.day is in the format of the last 7 days
              const date = new Date(item.year, item.month - 1, item.day);
              const index = 7 - Math.floor((new Date() - date) / (1000 * 60 * 60 * 24)); // Calculate the index for last 7 days
              if (index >= 0 && index <= 7) {
                acc[index] = item.totalBids; // Place total bids at the correct index for the last 7 days
              }
              return acc;
            }, new Array(7).fill(0)) // Initialize with zeroes for all 7 days
            : new Array(7).fill(0), // Default to all zeroes if no data
          fill: false,
          backgroundColor: 'rgba(75,192,192,0.2)',
          borderColor: 'rgba(75,192,192,1)',
          pointBorderColor: 'rgba(75,192,192,1)',
          pointBackgroundColor: '#fff',
          pointHoverBackgroundColor: 'rgba(75,192,192,1)',
          pointHoverBorderColor: 'rgba(220,220,220,1)',
          pointRadius: 5,
          pointHoverRadius: 8,
        },
      ],

    });
    setRows(detail?.bids?.data)
  }, [detail])


  return (
    <>
      <Grid container>
        <Grid item xs={6}>
          {data && <Line data={data} options={options} style={CSS.AdminDashboard.Graph} />}
        </Grid>
        <Grid item xs={6}>
          {data1 && <Line data={data1} options={options} style={CSS.AdminDashboard.Graph} />}
        </Grid>
      </Grid>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700, maxWidth: user.role_id === 1 ? '100%' : '96.8%', m: user.role_id === 1 ? 0 : 3 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Projects</StyledTableCell>
              <StyledTableCell align="right" colSpan={4}><Link to={'/allbids'} style={CSS.AdminDashboard.view}>View all</Link></StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows?.length === 0 && (
              <StyledTableRow>
                <StyledTableCell colSpan={6} align="center">
                  No Data Found
                </StyledTableCell>
              </StyledTableRow>
            )}
            {rows?.map((row) => (
              <StyledTableRow key={row?.id}>
                <StyledTableCell component="th" scope="row" sx={{ color: row?.project === "Unknown" ? "red" : "" }}>
                  {row?.project === "Unknown" ? "Deleted" : row.project}
                </StyledTableCell>
                <StyledTableCell  align="right" sx={{ color: row?.user === "Unknown" ? "red" : "" }}>{row?.user === "Unknown" ? "Deleted" : row?.user}</StyledTableCell>
                <StyledTableCell align="right">{row?.bidded_from}</StyledTableCell>
                <StyledTableCell align="right">{row?.formatted_date}</StyledTableCell>

              </StyledTableRow>
            ))}
          </TableBody>
        </Table>

      </TableContainer>
    </>
  );
};

export default AdminDashboard;
