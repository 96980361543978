import { Box, Button, Chip, FormLabel, Grid, IconButton, InputAdornment, Modal, Rating, Stack, TextField, Typography } from '@mui/material';
import React, { useEffect, useInsertionEffect, useLayoutEffect, useState } from 'react'
import LocationOnIcon from '@mui/icons-material/LocationOn';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import { API_URL, Img_URL } from '../Config/URL';
import { CSS } from './CSS';
import BrandingWatermarkIcon from '@mui/icons-material/BrandingWatermark';
import CreditScoreIcon from '@mui/icons-material/CreditScore';
import PaymentIcon from '@mui/icons-material/Payment';
import EmailIcon from '@mui/icons-material/Email';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import PersonIcon from '@mui/icons-material/Person';
import { yupResolver } from '@hookform/resolvers/yup'
import { Controller, useForm } from 'react-hook-form'
import axios from 'axios';
import { Formik, useFormik } from 'formik'
import EditIcon from '@mui/icons-material/Edit';
import Form from './Form';
import * as yup from 'yup'
import { useDispatch, useSelector } from 'react-redux';
import { CreateBid, Getbid } from '../redux/Projects/ProjectsAction';
import { storage } from '../Config/Storage';
import LaunchIcon from '@mui/icons-material/Launch';
import { Link } from 'react-router-dom';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import LoaderComponent from './Loader/LoaderComponent'
import CustomButton from './CustomButton';
// Utility function to convert newlines to <br /> tags
const convertNewlinesToBr = (text) => {
  return text?.replace(/\n/g, '<br />');
};

const Schema = {
  bid: yup.object().shape({
    amount: yup.number("Please enter valid number").required('Please enter the amout'),
    days: yup.number("Please enter valid number").required('Please enter the number of days'),
    description: yup
      .string()
      .required('Please enter the proposal').min(100, "Enter atleast 100 characters").max(1500, "Enter maximum 1500 characters")
  })
}

const ModalBox = ({ open, setopen, data, id, values, loading, setLoading }) => {

  const { bid, generated } = useSelector((state) => state?.ProjectData) || {};
  const dispatch = useDispatch()
  const [first, setfirst] = useState(false)
  const [date, setdate] = useState()
  const [rating, setRating] = useState()
  const [upgrades, setUpgrades] = useState(data?.upgrades);
  const [edit, setEdit] = useState(false)
  const minimum = Number(data?.budget?.minimum) || 0;
  const maximum = Number(data?.budget?.maximum) || 0;
  var am = Math.round(minimum + maximum) / 2
  const [defaultValues, setDefaultValues] = useState({
    amount: am,
    days: 7,
    description: ""
  })
  const formik = useFormik({
    initialValues: defaultValues,
    validationSchema: Schema.bid,
    onSubmit: (value) => {
      onSubmit(value)
    },
    enableReinitialize: true,
  })

  const onSubmit = (model) => {
    if (!data.bid_id) {
      model.amount = Number(model.amount)
      Object.assign(model, { project_id: data.id, account_used: storage.fetch.accountused() })
      dispatch(CreateBid(model))
    } else {
      Object.assign(model, { id: id })
      dispatch(CreateBid(model, values))
    }
    setopen(false)
    setEdit(!edit)
  }


  useEffect(() => {
    //Converting date to mm/dd/yyyy hh:mm:ss format
    const date = new Date(data?.owner?.registration_date * 1000);
    setRating(data?.rating)
    // Define options for formatting
    const options = {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    };
    setdate(date.toLocaleDateString('en-US', options))

    if (bid && data?.bid_id) {
      setDefaultValues({
        amount: Number(bid?.amount),
        days: bid?.period,
        description: bid?.description
      })
    } else {
      setDefaultValues({
        amount: am,
        days: 7,
        description: ""
      })
    }
    if (generated?.content && first) {
      setDefaultValues({
        amount: am,
        days: 7,
        description: generated?.content
      })
      setLoading(false)

    }
  }, [data, bid, generated])
  useEffect(() => {
    // Update state if `item.upgrades` changes
    setUpgrades(data?.upgrades);
  }, [data?.upgrades]);

  useEffect(() => {
    formik.resetForm(
      {
        amount: am,
        days: 7,
        description: ""
      }
    )
  }, [defaultValues])



  const handleEdit = () => {
    setEdit(!edit)
    dispatch(Getbid({ id: id }))
  };

  //Function to close Modal
  const handleClose = () => {
    formik.resetForm(
      {
        amount: am,
        days: 7,
        description: ""
      }
    )
    setDefaultValues({
      amount: am,
      days: 7,
      description: ""
    })
    setEdit(false)
    setopen(false)
    setfirst(false)
  };


  //Function to download Attachments
  const handleAttach = async (url, filename, ct) => {
    const fileUrl = API_URL + `projects/downloadfile`;
    try {
      const response = await axios({
        url: fileUrl,
        method: 'POST',
        responseType: 'blob', // Important for handling binary data
        data: {
          "url": url,
          "filename": filename,
          "content_type": ct
        },
        headers: {
          'Content-Type': 'application/json' // Ensure this matches what your backend expects
        }
      });

      // Create a new Blob object using the response data
      const blob = new Blob([response.data], { type: response.headers['content-type'] });

      // Create a link element
      const link = document.createElement('a');

      // Set the download attribute with the filename
      link.href = URL.createObjectURL(blob);
      link.download = filename;

      // Append the link to the body
      document.body.appendChild(link);

      // Trigger a click event on the link to start the download
      link.click();

      // Clean up by removing the link from the DOM
      document.body.removeChild(link);

      // Revoke the object URL after the download is triggered
      URL.revokeObjectURL(link.href);

    } catch (error) {
    }
  };



  return (

    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"

    >
      <Box className="modal" sx={CSS.Modal.modal}>
        <Box sx={CSS.Modal.box}>
          <Typography id="modal-modal-title" sx={{ width: "80%", fontWeight: 700, fontSize: '26px' }}>
            {data?.title}
            <Link to={Img_URL + "/projects/" + data?.seo_url} target="_blank" ><LaunchIcon /></Link>
          </Typography>
          <Grid container item xs={3} sm={3} md={3} lg={3} xl={3} columnGap={4}>
            <Grid>
              <Typography sx={CSS.Modal.head}>
                Bids
              </Typography>
              <Typography sx={CSS.Modal.value}>
                {data?.bid_stats?.bid_count}
              </Typography>
            </Grid>
            <Grid>
              <Typography sx={CSS.Modal.head}>
                Average Bid
              </Typography>
              <Typography sx={CSS.Modal.value}>
                {data?.currency?.sign}{Math.round(data?.bid_stats?.bid_avg)} {data?.currency?.code}
              </Typography>
            </Grid>
          </Grid>

        </Box>
        <Grid container item xs={12} sm={12} md={12} lg={12} xl={12} columnGap={6} sx={{ mb: 2 }}>

          <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
            <Box sx={CSS.Modal.box}>
              <Typography sx={CSS.Modal.heading}>Project Details</Typography>
              <Typography component="span">Budget : {data?.currency?.sign}{data?.budget?.minimum} - {data?.currency?.sign}{data?.budget?.maximum} {data?.currency?.code}</Typography>
            </Box>
            <Typography id="modal-modal-description" sx={{ mt: 2, mb: 2 }} >
              <div dangerouslySetInnerHTML={{ __html: convertNewlinesToBr(data?.description) }} />
            </Typography>
            <Typography sx={CSS.Modal.heading}>Skills Required</Typography>
            <Stack direction="row" spacing={1} rowGap={2} sx={{ pt: 2, display: 'flex', flexWrap: "wrap", mb: 2 }}>
              {data?.jobs?.map((i, ind) =>
                <Chip label={i.name} key={ind} />
              )}
            </Stack>
            {data?.attachments.length !== 0 &&
              <><Typography sx={CSS.Modal.heading}>Attachments</Typography>
                <Grid item display={"inline-grid"} xs={12} sx={{ minWidth: '100%' }}>
                  {data?.attachments?.map((item) =>
                    <Box sx={{ display: "flex", m: 0.5, }}>
                      <AttachFileIcon fontSize='18px' sx={{ mt: 1.2, mr: 2 }} />
                      <Button onClick={() => handleAttach(`https://${item.url}`, item.filename, item.content_type)} target='_blank'>{item.filename}</Button>
                    </Box>
                  )}
                </Grid></>}
            {upgrades?.sealed && <Chip label={"Sealed"} key="sealed" sx={{ bgcolor: '#589af0', color: "white", mr: 1 }} />}
            {upgrades?.NDA && <Chip label={"NDA"} key="nda" sx={{ bgcolor: '#115cc0', color: "white", mr: 1 }} />}
            {upgrades?.recruiter && <Chip label={"Recruiter"} key="recruiter" sx={{ bgcolor: '#7f21f7', color: "white", mr: 1 }} />}
            {upgrades?.ip_contract && <Chip label={"IP Contract"} key="ip_contract" sx={{ bgcolor: '#860146', color: "white", mr: 1 }} />}

            <Box style={{ fontSize: "12px", width: "100%" }}>Project ID:{data?.id}</Box>

          </Grid>

          <Grid item xs={12} md={2} sx={{
            ml: { xs: 0, md: 9 }, mt: 2, "&.MuiGrid-root": {
              flexBasis: { md: "22.666667%" }
            }
          }}>
            <Typography sx={{ fontSize: "20px", fontWeight: 600, mb: 1 }}>About the Client</Typography>
            <Grid display={"flex"}>
              <LocationOnIcon />
              <Box sx={{ ml: 2 }}>{data?.owner?.location?.country?.name}
                </Box>
                </Grid>
            <Grid display={"flex"} mt={1}>
              <img src={Img_URL + data?.owner?.location?.country?.flag_url} width={"25px"} height={"20px"} />
              <Box sx={{ ml: 2 }}>{data?.owner?.location?.city}</Box>
              </Grid>
            <Grid display={"flex"} mt={1}>
              <PersonIcon />
              <Box sx={{ display: 'flex', ml: 2 }}>  
                <Rating name="read-only" value={rating !== undefined ? rating?.toFixed(1) : 0} precision={0.1} readOnly />
                <Typography>{data?.rating !== undefined ? rating?.toFixed(1) : 0}</Typography>
                </Box>
                </Grid>
            <Grid display={"flex"} mt={1}>
              <AccessTimeFilledIcon />
              <Box sx={{ display: 'flex', ml: 2 }}>
                <Typography variant="body1"> {/* Use Typography for consistency */}
                  Member Since {date}
                </Typography>
              </Box>
            </Grid>
            <Typography sx={{ fontSize: "20px", fontWeight: 600, mb: 1, mt: 2 }}>Client Verification</Typography>
            <Grid display={"flex"} >
              <BrandingWatermarkIcon />
              <Box sx={{ display: 'flex', ml: 2 }}>
                {data?.owner?.status?.identity_verified ? "Identity verified" : "Identity not verified"}
                </Box>
              </Grid>
            <Grid display={"flex"} mt={1}>
              <CreditScoreIcon />
              <Box sx={{ ml: 2 }}>
                {data?.owner?.status?.payment_verified ? "Payment Verified" : "Payment not verified"}
                </Box>
                </Grid>
            <Grid display={"flex"} mt={1}>
              <EmailIcon />
              <Box sx={{ ml: 2 }}>
                {data?.owner?.status?.email_verified ? "Email Verified" : "Email not verified"}
                </Box>
                </Grid>
            <Grid display={"flex"} mt={1}>
              <PaymentIcon />
              <Box sx={{ ml: 2 }}>
                {data?.owner?.status?.deposit_made ? "Deposit made" : "Deposit not made"}
                </Box>
                </Grid>
            <Grid display={"flex"} mt={1}>
              <LocalPhoneIcon />
              <Box sx={{ ml: 2 }}>
                {data?.owner?.status?.phone_verified ? "Phone Verified" : "Phone not verified"}
                </Box>
                </Grid>
          </Grid>

        </Grid>
        {!data?.bid_id ?
          <Form add={true} data={data} defaultValues={defaultValues} formik={formik} setfirst={setfirst} loading={loading} setLoading={setLoading} handleClose={handleClose} />
          :
          <Grid container xs={12} sm={12} md={12} lg={12} xl={12} columnGap={3}>
            <Typography>You have already placed a bid on this project.</Typography>
            <EditIcon sx={{ cursor: "pointer" }} onClick={handleEdit} />
          </Grid>
        }
        {edit && <Form add={false} data={data} defaultValues={defaultValues} formik={formik} setLoading={setLoading} loading={loading} setfirst={setfirst} handleClose={handleClose} />}

      </Box>

    </Modal>
  )
}

export default ModalBox