import React, { useEffect, useState } from 'react'
import AddEditForm from '../../../Components/AddEditForm'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { useDispatch, useSelector } from 'react-redux'
import { addeditUser, getUserbyid } from '../../../redux/Admin/AdminAction'
import { useLocation, useNavigate } from 'react-router-dom'
import CryptoJS from 'crypto-js';
import Toastify from '../../../Components/SnackBar/Toastify'



const EditUser = () => {
    const { user } = useSelector((state) => state.AdminData) || {};
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation()
    const [InitalValues, setInitalValues] = useState({
        firstname: "",
        lastname: "",
        email: "",
        role_id: "",
        password: "",
        confirmPassword: ""
    })
    const [updatepassword, setUpdatepassword] = useState(false)
    const Schema = yup.object().shape({
        firstname: yup.string().required('Please enter the first name'),
        lastname: yup.string().required('Please enter the Last name'),
        role_id: yup.string().required('Please select the role'),
        email: yup.string().email("Please enter a valid email").required('Please enter the email'),
        password: updatepassword && yup
            .string()
            .required('Please enter your password.')
            .min(8, 'Password is too short - should be 8 char minimum.'),
        confirmPassword: updatepassword && yup
            .string()
            .required('Confirm your password.')
            .min(8, 'Password is too short - should be 8 char minimum.')
            .oneOf([yup.ref('password'), null], 'Passwords must match'),
    })
    const encryptedUserId = location.pathname.split("/").pop();
    let id
    const secretKey = 'mySecretKey123!'; // Your encryption key

    // Reverse the URL-safe replacements
    let encryptedStr = encryptedUserId
        .replace(/_/g, '/')  // Replace "_" back to "/"
        .replace(/-/g, '+'); // Replace "-" back to "+"

    // Decrypt the string
    const bytes = CryptoJS.AES.decrypt(encryptedStr, secretKey);
    const originalId = bytes.toString(CryptoJS.enc.Utf8); // Get the original ID
    id = originalId

    useEffect(() => {
        dispatch(getUserbyid({ user_id: id }))
    }, [])
    useEffect(() => {
        setInitalValues({
            firstname: user?.firstname,
            lastname: user?.lastname,
            email: user?.email,
            role_id: user?.role_id,
        })
    }, [user])


    const formik = useFormik({
        initialValues: InitalValues,
        validationSchema: Schema,
        onSubmit: (value) => {
            onSubmit(value)
        },
        enableReinitialize: true,
    })

    const onSubmit = (model) => {
        if (id)
            Object.assign(model, { id: id })
        dispatch(addeditUser(model, navigate))
    }

    return (
        <>
            <Toastify />
            <AddEditForm formik={formik} id={id} updatepassword={updatepassword} setUpdatepassword={setUpdatepassword} edit={true} />
        </>
    )
}

export default EditUser