import { toast } from "react-toastify";
import { API_URL } from "./URL";
import axios from "axios";
import { storage } from "./Storage";

export const GetRequest = async (url, data) => {
      try {
        const res = await axios({
          url: API_URL + url,
          method: 'GET',
          params: data,
          headers:{
            Authorization:storage.fetch.auth()
          }
        })
        return res
      } catch (err) {
        if(err.status===500){
          toast.error(err.response.data.error)
        }
        if(err.response.data.code===403){
          toast.error(err.response.data.message)
          storage.destory.auth()
          setTimeout(() => {
        window.location.replace("/")
            
          }, 2000);
        }
      }
}

export const PostRequest = async (url, data) => {
    
  try {
    const res = await axios.post(API_URL+url, data, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization:storage.fetch.auth()
      },
    });
    
    return res.data; // returning the data part of the response
  } catch (err) {
    return err.response.data
  }
}
export const Api = {
  PostRequest,
  GetRequest
};
