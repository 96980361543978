export const GET_LOADING = "GET_LOADING";
export const SET_LOADING = "SET_LOADING";
export const GET_LOGIN = "GET_LOGIN";
export const SET_LOGIN = "SET_LOGIN";
export const GET_REGISTER = "GET_REGISTER";
export const SET_REGISTER = "SET_REGISTER";
export const GET_DASHBOARD = "GET_DASHBOARD";
export const SET_DASHBOARD = "SET_DASHBOARD";
export const GET_PROJECTS = "GET_PROJECTS";
export const SET_PROJECTS = "SET_PROJECTS";
export const GET_PROJECTS_ID = "GET_PROJECTS_ID";
export const SET_PROJECTS_ID = "SET_PROJECTS_ID";
export const GET_BID_CREATE_UPDATED = "GET_BID_CREATE_UPDATED";
export const SET_BID_CREATE_UPDATED = "SET_BID_CREATE_UPDATED";
export const GET_BIDS = "GET_BIDS";
export const SET_BIDS = "SET_BIDS";
export const GET_ALL_BIDS = "GET_ALL_BIDS";
export const SET_ALL_BIDS = "SET_ALL_BIDS";
export const GET_BID = "GET_BID";
export const SET_BID = "SET_BID";
export const GET_USERS = "GET_USERS";
export const SET_USERS = "SET_USERS";
export const GET_COOKIE = "GET_COOKIE";
export const SET_COOKIE = "SET_COOKIE";
export const GET_AI_BID = "GET_AI_BID";
export const SET_AI_BID = "SET_AI_BID";
export const GET_MONTHLY_AVG = "GET_MONTHLY_AVG";
export const SET_MONTHLY_AVG = "SET_MONTHLY_AVG";
export const GET_WEEKLY_AVG = "GET_WEEKLY_AVG";
export const SET_WEEKLY_AVG = "SET_WEEKLY_AVG";
export const GET_LAST_BIDS = "GET_LAST_BIDS";
export const SET_LAST_BIDS = "SET_LAST_BIDS";
export const GET_ADD_EDIT_USER = "GET_ADD_EDIT_USER";
export const SET_ADD_EDIT_USER = "SET_ADD_EDIT_USER";
export const GET_DELETE_USER = "GET_DELETE_USER";
export const SET_DELETE_USER = "SET_DELETE_USER";
export const GET_USER_BY_ID = "GET_USER_BY_ID";
export const SET_USER_BY_ID = "SET_USER_BY_ID";