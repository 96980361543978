import {
  AppBar,
  Box,
  Button,
  CssBaseline,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect } from "react";
import { Header, Sidebar } from "../Config/Mockdata";
import MenuIcon from "@mui/icons-material/Menu";
import { NavLink, RouterProvider, useLocation, useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import { storage } from "../Config/Storage";
import { toast } from "react-toastify";
import router from "../routes/Routes";
import Index from "../routes";
import Heading from "./Heading";
import AlertDialog from "./AlertDialog";

const CompDrawer = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints?.down("sm"));
  const isIpad = useMediaQuery(theme.breakpoints?.down("lg"));
  const isLargeDevice = useMediaQuery(theme.breakpoints?.down("lg"));
  const [open, setOpen] = React.useState(isMobile || isIpad ? false : true);
  const [openLogoutAlert, setOpenLogoutAlert] = React.useState(false);
  const user = storage.fetch.user()

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  // React.useEffect(() => {
  //   function handleResize() {
  //     if (window.innerWidth >= 1200) {
  //       setOpen(true);
  //     } else {
  //       setOpen(false);
  //     }
  //   }

  //   // Attach the event listener to the window object
  //   window.addEventListener("resize", handleResize);

  //   // Remove the event listener when the component unmounts
  //   return () => {
  //     window.removeEventListener("resize", handleResize);
  //   };
  // }, []);

  const Logout = () => {
    setOpenLogoutAlert(true)
  };

  const logOutAdmin = () => {
    // setOpenLogoutAlert(false);
    // navigate("/login");
    // storage.destory.auth();
    // storage.destory.contractKey();
    // setTimeout(() => {
    //   toast.success("Logged out succesfully.");
    // }, 2000);
    // localStorage.clear();
    setOpenLogoutAlert(false);
    storage.destory.auth();
    navigate("/");
    setTimeout(() => {
      toast.success("Logged out Successfully");
    }, 2000);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <>
      <CssBaseline />
      <AppBar
        sx={{
          bgcolor: "#ffffff",
          display: "flex",
          boxShadow: "none",
          borderBottom: "2px solid rgba(0, 0, 0, 0.06)",
          pt: 1.5,
          pb: 1.5,
          "&.MuiAppBar-root": {
            position: "unset",
          },
        }}>
        <Toolbar
          sx={{
            display: "flex",
            justifyContent: "space-between",
            ml: 40,
            "&.MuiToolbar-root": {
              minHeight: "16px"
            }
          }}>
          {user.role_id === 1 && Header.map((text) => {
            if (text.path === location.pathname||text.path === location.pathname.slice(0,11)) {
              return <Heading key={text.path} headingtext={text.link} />;
            }
            // return <Heading key={""} headingtext={} />;
          })}
        
        </Toolbar>
      </AppBar>

      <Drawer
        onClose={false}
        sx={{
          minWidth: "300px",
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: "300px",
            boxSizing: "border-box",
          },
          ml: "30px",
          mr: "30px",
        }}
        id="drawer"
        variant={isLargeDevice ? "temporary" : "persistent"}
        anchor="left"
        open={true}
      >
        <List
          className="drawerItem"
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            height: "100%",
            "& .MuiListItemButton-root:hover": {
              borderRadius: "8px",
              fontWeight: "400",
              "&, & .MuiListItemIcon-root": {
                fontWeight: "400",
                borderRadius: "8px",
              },
            },
            p: { xs: "0px 10px 0px 10px", sm: "0px 25px 8px 25px" },
          }}
        >
          <Box
            sx={{
              pt: 3,
              pb: 3,
            }}
          >
            <img src={require("../assets/logo.png")} width={"150px"} />
          </Box>

          {Sidebar.map((text, index) => (
            <ListItem
              sx={{
                bgcolor: text?.isActive ? "#27245E" : "#EEF2FC", // Active color\
                border:text.path === location.pathname?"2px solid black":null,
                borderRadius: "8px",
                mb: "15px",
                "&:hover": {
                  bgcolor: text?.isActive ? "#3E3A91" : "#D0D8EF", // Darker hover for active
                },
              }}
              key={index}
              disablePadding
            >
              <NavLink
                className={`drawerItemLinks ${index === Sidebar.length - 1 ? "no-active" : ""
                  } ${index === Sidebar.length - 2 ? "disabled-link" : ""}`}
                style={{
                  color: text?.isActive ? "white" : "#27245E", // Active text color
                  width: "100%",
                  textDecoration: "none",
                  display: "flex",
                  padding: "6px 12px",
                  fontSize: "16px",
                  fontWeight: text?.isActive ? "bold" : "400", // Active font weight
                }}
                to={text?.path}
                onClick={() => (index === Sidebar.length - 1 ? Logout() : "", isMobile ? setOpen(false) : null)}
              >
                <ListItemText
                  sx={{
                    fontWeight: text?.isActive ? "bold" : "400", // Active font weight in ListItemText
                    fontSize: "16px",
                  }}
                  primary={text?.link}
                />
              </NavLink>
            </ListItem>
          ))}
        </List>
      </Drawer>

      <Box
        sx={{
          width: {
            lg: open ? `calc(100% - 300px)` : "100%",
            xs: "100%",
          },
          ml: open ? "auto" : "",
        }}
        className={open && isIpad ? "hightlight-body" : ""}>
        <Box sx={{ p: { xs: "40px 15px 40px 15px", sm: "20px 40px 40px 40px" } }}>
          <Index />
        </Box>
      </Box>
      <Box>
        <AlertDialog
          title={"Are you sure you want to logout?"}
          open={openLogoutAlert}
          onClickButtonCancel={() => setOpenLogoutAlert(false)}
          onClickButton={() => logOutAdmin()}
        />
      </Box>
    </>
  );
};

export default CompDrawer;
