import { put, takeEvery } from "redux-saga/effects";
import { GET_AI_BID, GET_ALL_BIDS, GET_BID, GET_BID_CREATE, GET_BID_CREATE_UPDATED, GET_BIDS, GET_COOKIE, GET_PROJECTS, GET_PROJECTS_ID, GET_USERS, SET_AI_BID, SET_ALL_BIDS, SET_BID, SET_BID_CREATE, SET_BID_CREATE_UPDATED, SET_BIDS, SET_COOKIE, SET_PROJECTS, SET_PROJECTS_ID } from "../ConstAction";
import projectsApiRequests from "../../services/Projects";
import { toast } from "react-toastify";
function* getprojects(data) {
  try {
    let response = yield projectsApiRequests.getprojects(data.data);
      yield put({
        type: SET_PROJECTS,
        response,
      });
  } catch (err) {}
}
function* getprojectsid(data) {
  try {
    let response = yield projectsApiRequests.getprojectsid(data.data);
      yield put({
        type: SET_PROJECTS_ID,
        response,
      });
  } catch (err) {}
}
function* getbidposted(data) {
  try {
    let response
    if(data.data.id){
      response = yield projectsApiRequests.updatebid(data.data);
    }else
     response = yield projectsApiRequests.postbid(data.data);
    
      yield put({
        type: SET_BID_CREATE_UPDATED,
        response,
      });
      
      yield put({
        type: GET_BIDS,
        data: data.values,
      });
      setTimeout(() => {
        if(response?.data?.status){
        if(data.data.id){
        toast.success("Bid updated Successfully")
        }else{
          toast.success("Bid created Successfully")
        }}else{
          toast.error(response?.data?.message)
        }
      }, 2000);

  } catch (err) {}
}
function* getbid(data) {
  try {
    let response
      response = yield projectsApiRequests.getbid(data.data);
      yield put({
        type: SET_BID,
        response,
      });
  } catch (err) {}
}
function* getbids(data) {
  try {
    let response
      response = yield projectsApiRequests.getbids(data.data);
    yield put({
      type: GET_USERS,
      data:{page:1}
    });
    if(response.data.status){
      yield put({
        type: SET_BIDS,
        response,
      });
      
      }
  } catch (err) {}
}

function* getCookie(data) {
  try {
    let response = yield projectsApiRequests.cookie(data.data);
    if(response){
    yield put({
        type: SET_COOKIE,
        response:response
      });}
  } catch (err) {

  }
}
function* getaibid(data) {
  try {
    let response = yield projectsApiRequests.requestaibid(data.data);
    if(response.status){
    yield put({
        type: SET_AI_BID,
        response:response
      });}else{
        toast.error(response.data.message)
      }
  } catch (err) {

  }
}
function* getallbids(data) {
  try {
    let response = yield projectsApiRequests.requestallbids(data.data);
    if(response.status){
    yield put({
        type: SET_ALL_BIDS,
        response:response
      });}else{
        toast.error(response.data.message)
      }
  } catch (err) {

  }
}

function* projectSaga() {
  yield takeEvery(GET_PROJECTS, getprojects);
  yield takeEvery(GET_PROJECTS_ID, getprojectsid);
  yield takeEvery(GET_BID_CREATE_UPDATED, getbidposted);
  yield takeEvery(GET_BID, getbid);
  yield takeEvery(GET_BIDS, getbids);
  yield takeEvery(GET_COOKIE, getCookie);
  yield takeEvery(GET_AI_BID, getaibid);
  yield takeEvery(GET_ALL_BIDS, getallbids);

}
export default projectSaga;
