import { SET_LOADING, SET_LOGIN, SET_USERS } from "../ConstAction";
const initialState = {};
export const UserData = (state = initialState, action) => {
  switch (action.type) {
    case SET_LOGIN:
      return {
        ...state,
        details: action.response.data,
        loading: false,
      };
    case SET_LOADING:
      return {
        loading: action.data,
      };
      case SET_USERS:
      return {
        userDetails: action?.response?.data,
      };
    default:
      return {...state};
  }
};
