import { Box, Button, FormLabel, Grid, TextareaAutosize } from '@mui/material';
import React, { useState } from 'react';
import Heading from '../../Components/Heading';
import Header from '../../Components/Header';
import { CSS } from '../../Components/CSS';
import { useDispatch } from 'react-redux';
import { updateCookie } from '../../redux/Projects/ProjectsAction';
import CustomButton from '../../Components/CustomButton';

const Settings = () => {
    const dispatch = useDispatch()
    // State to store the value of the textarea
    const [cookieValue, setCookieValue] = useState('');

    // Function to handle textarea value change
    const handleChange = (e) => {
        setCookieValue(e.target.value);
    };

    // Function to handle button click
    const handleUpdate = () => {
        dispatch(updateCookie({ cookie: cookieValue }))
    };

    return (
        <Grid>
            <Header />
            <Box sx={CSS.Dashboard.box}>
                <Heading headingtext={"Settings"} />
            </Box>
            <Box sx={CSS.Settings.box}>
                <FormLabel sx={{ mr: 2 }}> Update Cookie </FormLabel>
                <TextareaAutosize
                    name='cookie'
                    value={cookieValue} // Set value to the state
                    onChange={handleChange} // Update state on change
                    style={{ minHeight: "150px", minWidth: "500px" }}
                />
            </Box>
            <Box sx={{ ml: 18 }}>
                <CustomButton color={"#4cd964"} onclick={handleUpdate} name={"Update"} />
            </Box>
        </Grid>
    );
}

export default Settings;
