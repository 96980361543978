import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Box, CircularProgress, IconButton, InputAdornment, TextField, Typography } from '@mui/material';
import Card from '../../Components/Card';
import { ArrowUpward } from '@mui/icons-material';
import { API_URL } from '../../Config/URL';
import { CSS } from '../../Components/CSS';
import Toastify from '../../Components/SnackBar/Toastify';
import LoaderComponent from '../../Components/Loader/LoaderComponent';
import RefreshIcon from '@mui/icons-material/Refresh';
import SelectField from '../../Components/SelectField';
import { useDispatch, useSelector } from 'react-redux';
import { Allprojects } from '../../redux/Projects/ProjectsAction';
import Header from '../../Components/Header';
import Heading from '../../Components/Heading';

const Dashboard = () => {
  const { result } = useSelector((state) => state?.ProjectData) || {};
  const dispatch = useDispatch();
  const [list, setList] = useState([]);
  const [results, setResults] = useState({});
  const [load, setLoad] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [visible, setVisible] = useState(false);
  const [alignment, setAlignment] = useState('All'); // Manage selected value
  const [values, setValues] = useState({
    page: 1,
    limit: 10,
    offset: 0,
    search: "",
  });

  // Handle selection change
  const handleChange = (event) => {
    setAlignment(event.target.value);
    setLoaded(false);
    setValues({ ...values, offset: 0, page: 1 }); // Reset pagination

  };

  // Function to handle scroll
  useEffect(() => {
    const handleScroll = () => {
      const scrolled = window.scrollY;
      const totalHeight = document.documentElement.scrollHeight;
      const visibleHeight = window.innerHeight;
      const remainingSpace = totalHeight - (scrolled + visibleHeight);

      setVisible(scrolled > 300);

      if (remainingSpace < 10 && !load) {
        setLoad(true);
        setLoaded(true);
        setValues(prevValues => ({ ...prevValues, offset: prevValues.offset + 10 }));
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [load]);

  // Fetch more projects when values change (pagination)
  useEffect(() => {
    dispatch(Allprojects({ ...values, type: alignment }));
    setLoading(true)
  }, [values, alignment]);

  useEffect(() => {
    if (result && loaded) {
      setList(prevList => [...prevList, ...result?.projects]);
      setResults(prevResult => ({
        ...prevResult,
        ...result?.users
      }));
      setLoading(false)
      setLoaded(true);
      setLoad(false);
    } else {
      setList(result?.projects);
      setResults(result?.users);
      setLoading(false)

    }
  }, [result]);

  // Function to scroll to the top
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  // Handle refresh
  const handleRefresh = () => {
    setAlignment("All"); // Reset to default value
    setLoaded(false);
    setValues({ page: 1, limit: 10, offset: 0, search: "" }); // Reset pagination
  };

  // Handle Search
  const handleSearch = (e) => {
    setValues({ page: 1, limit: 10, offset: 0, search: e.target.value })
  };

  // Handle MAX and MIN PRICE
  const handleprice = (e) => {
    if (e.target.name === "max_price")
      setValues(prevValues => ({
        ...prevValues,
        max_price: e.target.value,
      }));
    else
      setValues(prevValues => ({
        ...prevValues,
        min_price: e.target.value,
      }));


  };
  return (
    <>
      <Header />
      <Box sx={CSS.Dashboard.box}>
        <Heading headingtext={"Projects"} />
        <Box sx={{ display: 'flex' }}>
          <TextField
            InputProps={{
              endAdornment: (
                <InputAdornment  position="end">
                  <Typography>USD</Typography>
                </InputAdornment>
              )
            }}
            inputProps={{
              style: {
                paddingBottom: '10px',
              }
            }}
            name='min_price'
            label="Min price"
            variant="outlined"
            placeholder='Min price'
            value={values.min_price}
            onChange={handleprice} sx={{ minWidth: 200, mr: 1 }}

          />
          <TextField
            InputProps={{
              pb: 2,
              endAdornment: (
                <InputAdornment position="end">
                  <Typography>USD</Typography>
                </InputAdornment>
              )
            }}
            inputProps={{
              style: {
                paddingBottom: '10px',
              }

            }}
            label="Max price"
            name='max_price'
            variant="outlined"
            placeholder='Max price'
            value={values.max_price}
            onChange={handleprice}
            sx={{ minWidth: 200 }}

          />
          <SelectField menuData={["All", "hourly", "fixed"]} def={alignment} handleChangeForSelect={handleChange} />
          <TextField
            InputProps={{
            }}
            inputProps={{
              style: {
                paddingBottom: '10px',
              }
            }}
            label="Search"
            placeholder='Search'
            variant="outlined"
            value={values.search}
            onChange={handleSearch} sx={{ minWidth: 100 }} />
          <IconButton onClick={handleRefresh}> <RefreshIcon /></IconButton>
        </Box>
      </Box>
      <LoaderComponent open={loading} />
      <Card list={list} values={values} results={results} />
      <ArrowUpward
        onClick={scrollToTop}
        style={{
          display: visible ? 'flex' : 'none',
          background: '#4cd964',
          borderRadius: '50%',
          color: 'white',
          cursor: 'pointer',
          position: 'fixed',
          bottom: '20px',
          right: '20px',
          zIndex: 1000
        }}
      />
      {!load && (
        <Box sx={CSS.Dashboard.loader}>
          <CircularProgress
            sx={CSS.Dashboard.Progress}
          />
        </Box>
      )}
      <Toastify />
    </>
  );
}

export default Dashboard;
