import { Box, Grid, Button, Container, TextField, FormLabel, Typography } from "@mui/material";
import React, { createRef, useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import Toastify from "../Components/SnackBar/Toastify";
import * as yup from 'yup'
import { toast } from "react-toastify";
import { API_URL } from "../Config/URL";
import { storage } from "../Config/Storage";
import { GET_LOGIN } from "../redux/ConstAction";
import { getLoading, getLogin } from "../redux/User/UserAction";
import { useDispatch } from "react-redux";
import ReCAPTCHA from "react-google-recaptcha";
import LoaderComponent from "../Components/Loader/LoaderComponent";
import CustomButton from "../Components/CustomButton";
import { CSS } from "../Components/CSS";

const defaultValues = {
  email: "",
  password: "",
};
const Schema = {
  login: yup.object().shape({
    email: yup.string().email("Please enter a valid email").required('Please enter the email'),
    password: yup.string().required('Please enter the password'),

  })
}
const SignIn = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const recaptchaRef = createRef();
  const [isFocused, setIsFocused] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleFocus = (event) => {
    event.preventDefault();
    setIsFocused(false);
  };
  const handleBlur = () => {
    setIsFocused(false);
  };
  useEffect(() => { }, []);

  const { control, formState, handleSubmit } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(Schema.login),
  });
  const { errors } = formState;
  const onSubmit = async (model) => {
    const recaptchaInstance = recaptchaRef.current;
    setLoading(true)

    if (recaptchaInstance) {
      try {
        const token = await recaptchaInstance.execute();
        model.captchaValue = token;

        dispatch(getLogin(model, navigate));
        setLoading(false)
      } catch (error) {
        toast.error('Error occurred during CAPTCHA verification');
        setLoading(false)
      }
    } else {
      toast.error('Please complete the CAPTCHA');
      setLoading(false)
    }
  };

  return (
    <>
      <Box sx={CSS.Login.outBox} className="form-screens">
        <Container maxWidth="xl" sx={{
          "&.MuiContainer-root": {
            pl: 0
          }
        }}>
          <LoaderComponent open={loading} />
          <Box sx={CSS.Login.mainBox}>
            <Box
              sx={{
                width: "50%",
                height: "calc(100vh - 100px)",
                p: "35px 40px 40px 40px",
                display: { xs: "none", md: "flex" },
                alignItems: "center",
              }}>
              <img src={require("../assets/login.jpg")} width={"80%"} />
            </Box>
            <Box sx={CSS.Login.leftBox}>
              <Box
                sx={{
                  width: "80%",
                  pb: 5,
                  display: "flex",
                }}>
                <Box>
                  <Typography sx={CSS.Login.logo}>Sign In</Typography>
                </Box>
              </Box>
              <form
                name="LoginForm"
                className="loginForm"
                onSubmit={handleSubmit(onSubmit)}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  height: "80%",
                  width: "80%",
                }}>
                <Grid>
                  <FormLabel sx={{ color: "#333" }}>Email<span style={CSS.Login.span}>*</span></FormLabel>
                  <Controller
                    name={"email"}
                    control={control}
                    sx={{
                      mt: 10,
                      pb: 0,
                    }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        fullWidth
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                        helperText={errors?.email?.message}
                        errors={!!errors.email}
                        sx={CSS.Login.input}
                        name="email"
                      />
                    )}
                  />
                </Grid>
                <Box sx={{ height: "15px" }} />

                <Grid>
                  <FormLabel sx={{ color: "#333" }}>Password<span style={CSS.Login.span}>*</span></FormLabel>
                  <Controller
                    name={"password"}
                    control={control}
                    sx={{
                      pb: 0,
                    }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        fullWidth
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                        type="password"
                        name="password"
                        helperText={errors?.password?.message}
                        errors={!!errors.password}
                        sx={CSS.Login.input}
                      />
                    )}
                  />
                </Grid>
                <ReCAPTCHA
                  ref={recaptchaRef}
                  sitekey="6LeybiUqAAAAAMG9fVcJg3Kxvoc-wPmaUQHdC9fe"
                  size="invisible"
                />
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: { md: "flex-start" },
                    mt: 4,
                    mb: 2,
                    pr: { xs: 1, md: 10 },
                    // pl: { xs: 0, sm: 1 },
                  }}>
                  {/* <Button type="submit" sx={Style.btn}>
                    Sign in
                  </Button> */}
                  <CustomButton type="submit" color={"#7d674a"} name={"Sign In"} />
                  <Toastify />
                </Box>
              </form>
            </Box>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default SignIn;

