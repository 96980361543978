import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Box, Button, Checkbox, Typography } from "@mui/material";
import CustomButton from "./CustomButton";

const AlertDialog = ({ title, image, text, open, onClickButton, onClickButtonCancel, forgot, fullWidth, minHeight, handlecheck }) => {
  return (
    <div>
      <Dialog
        open={open}
        PaperProps={{
          sx: {
            minHeight: minHeight,
          },
        }}
        fullWidth={fullWidth}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle sx={{ fontSize: "18px", color: "#3D2E57" }} id="alert-dialog-title">
          {title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ fontSize: "16px", color: "#A8A8A8", minWidth: "260px" }} id="alert-dialog-description">
            {text}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {/* <Button
            variant="contained"
            sx={{
              textTransform: "none",
              bgcolor: "#D80027",
              "&.MuiButtonBase-root:hover": {
                bgcolor: "#D80027",
              },
            }}
            onClick={onClickButtonCancel}>
            Cancel
          </Button> */}
          <CustomButton color={"#D80027"} onclick={onClickButtonCancel} name={"Cancel"} />
          <CustomButton color={"black"} onclick={onClickButton} name={"Confirm"}/>

          {/* <Button
            variant="contained"
            sx={{
              bgcolor: "black",
              textTransform: "none",
              "&.MuiButtonBase-root:hover": {
                bgcolor: "black",
              },
            }}
            onClick={onClickButton}>
            Confirm
          </Button> */}
        </DialogActions>
      </Dialog>
    </div>
  );
};
export default AlertDialog;
const Style = {
  validationStyle: {
    color: "red",
    margin: "10px",
  },
};
