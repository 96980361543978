import React, { useEffect, useState } from 'react'
import { CSS } from './CSS';
import { Button, FormLabel, Grid, InputAdornment, TextareaAutosize, TextField, Typography } from '@mui/material';
import { Formik, useFormik } from 'formik';
import axios from 'axios';
import { API_URL } from '../Config/URL';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';
import { generatewithAI } from '../redux/Projects/ProjectsAction';
import LoaderComponent from './Loader/LoaderComponent';
import CustomButton from './CustomButton';

const Form = ({ add, defaultValues, data, formik, loading, setfirst, handleClose, setLoading }) => {
  const { generated } = useSelector((state) => state?.ProjectData) || {};
  const dispatch = useDispatch()
  const [charCount, setCharCount] = useState(0);
  const maxCharLimit = 1500;

  useEffect(() => {
   
  }, [formik, generated?.content])
  const generate = () => {
    let sendingData = `
    Write me a bid and queries for the following: 
    title : ${data.title}
    description:${data.description}
  `
    dispatch(generatewithAI({ data: sendingData }))
    setfirst(true)
    // setLoading(true)

  }

  // Handle the change event for the textarea
  const handleTextareaChange = (e) => {
    const { value } = e.target;

    // Calculate character length without spaces and newlines
    const effectiveLength = value.replace(/[\s\n]/g, '').length; // Remove spaces and newlines

    // Check character length before updating formik and character count
    if (effectiveLength <= maxCharLimit) {
      formik.setFieldValue('description', value); // update formik state
      setCharCount(effectiveLength); // update character count
    }
  };
  return (
    <form onSubmit={formik.handleSubmit}>
      <Typography sx={CSS.Modal.heading}>{add ? "Place a Bid on this project" : "Edit your Bid"}</Typography>
      <Grid container columnGap={2}>
        <Grid item>
          <FormLabel >Bid Amount</FormLabel>
          <TextField
            value={formik?.values?.amount}
            name='amount'
            onChange={formik.handleChange}
            inputProps={{
              style: {
                height: "10px",
              }
            }}
            InputProps={{
              pt: 1,
              startAdornment:
                <InputAdornment position="start">
                  <Typography
                    sx={{
                      '&.MuiIconButton-root': {
                        borderRadius: '0%',
                        borderLeft: '0px',
                        p: '8.5px',
                        bgcolor: '#F2F2F2',
                      },
                    }}
                    edge="end"
                  >{data?.currency?.sign}
                  </Typography>
                </InputAdornment>,
              endAdornment:
                <InputAdornment position="end">
                  <Typography
                    sx={{
                      '&.MuiIconButton-root': {
                        borderRadius: '0%',
                        borderLeft: '0px',
                        p: '8.5px',
                        bgcolor: '#F2F2F2',
                      },
                    }}
                    edge="end"
                  >{data?.currency?.code}
                  </Typography>
                </InputAdornment>
            }}
            sx={CSS.Modal.field} />
          {formik.errors.amount && formik.touched.amount ? (
            <p style={CSS.Modal.validationStyle}>{formik.errors.amount}</p>
          ) : null}
        </Grid>
        <Grid>
          <FormLabel>This project will be delivered in</FormLabel>
          <TextField
            type='number'
            name='days'
            onChange={formik.handleChange}
            value={formik?.values?.days}
            inputProps={{
              style: {
                height: "10px",
              }
            }}
            InputProps={{
              pt: 1,
              endAdornment:
                <InputAdornment position="end">
                  <Typography
                    sx={{
                      '&.MuiIconButton-root': {
                        borderRadius: '0%',
                        borderLeft: '0px',
                        p: '8.5px',
                        bgcolor: '#F2F2F2',
                      },
                    }}
                    edge="end"
                  >Days
                  </Typography>
                </InputAdornment>
            }}
            sx={CSS.Modal.field} />
          {formik.errors.days && formik.touched.days ? (
            <p style={CSS.Modal.validationStyle}>{formik.errors.days}</p>
          ) : null}
        </Grid>
      </Grid>
      <Grid>
        <Grid sx={{ display: 'flex', justifyContent: "space-between", width: "70%" }}>
          <FormLabel >Describe your proposal (minimum 100 characters) </FormLabel>
          {/* <Button sx={CSS.Modal.btn} style={{ width: '150px', bottom: "20px", }} onClick={() => generate()}>Generate with AI</Button> */}
        </Grid>
        <TextareaAutosize name='description' value={formik?.values?.description} style={{ width: "70%" }} onBlur={formik.handleChange}
          onChange={handleTextareaChange} minRows={7} sx={CSS.Modal.field} />
        {formik.errors.description && formik.touched.description ? (
          <p style={CSS.Modal.validationStyle}>{formik.errors.description}</p>
        ) : null}
        <p style={CSS.Modal.validationStyle}>
          {maxCharLimit-charCount} characters left
        </p>
      </Grid>
      <Grid container  >
        <CustomButton name={"Submit"} color={"#4cd964"} type={"submit"}/>
        <CustomButton name={"Close"} ml={2} color={"red"} onclick={handleClose}/>
      </Grid>
    </form>
  )
}

export default Form