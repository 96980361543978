import { Box, IconButton, Pagination, Paper, styled, Table, TableBody, TableCell, tableCellClasses, TableContainer, TableHead, TableRow } from '@mui/material'
import React, { useEffect, useState } from 'react'
import Toastify from '../../../Components/SnackBar/Toastify'
import { storage } from '../../../Config/Storage'
import { users } from '../../../redux/User/UserAction';
import { useDispatch, useSelector } from 'react-redux';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CustomButton from '../../../Components/CustomButton';
import { useNavigate } from 'react-router-dom';
import { CSS } from '../../../Components/CSS';
import AlertDialog from '../../../Components/AlertDialog';
import { deleteuser } from '../../../redux/Admin/AdminAction';
import CryptoJS from 'crypto-js';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));
const Users = () => {
    const detail = useSelector((state) => state) || {};
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = storage.fetch.user()
    const [rows, setRows] = useState();
    const [pages, setPages] = useState();
    const [ID, setID] = useState();
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(true);
    const [values, setValues] = useState({
        page: 1,
        limit: 5
    });
    const secretKey = 'mySecretKey123!';
    useEffect(() => {
        dispatch(users(values));
    }, [values]);

    useEffect(() => {
        if (detail) {
            setRows(detail?.UserData?.userDetails?.data);
            setPages(detail?.UserData?.userDetails?.pagination?.totalPages);
            setLoading(false)

        }
    }, [detail]);

    const handleEdit = (id) => {
        const encryptedData = CryptoJS.AES.encrypt(id.toString(), secretKey).toString();
        const id1 = encryptedData
            .replace(/\//g, '_')  // Replace "/" with "_"
            .replace(/\+/g, '-')  // Replace "+" with "-"
            .replace(/=/g, '');
        navigate('/users/edit/' + id1)
    };
    const handleDelete = (id) => {
        setOpen(true);
        setID(id);
    };

    const handledeleteuser = () => {
        dispatch(deleteuser({ user_id: ID }))
        setOpen(false)
        dispatch(users(values));

    };
    ///---Pagination Function---///
    const handlePageChange = (event, value) => {
        setValues({ page: value, limit: 5 });
    };
    const handleAdd = () => {
        navigate("/users/add")
    };
    return (
        <>
            <Box sx={CSS.Users.addbtn}>
                <CustomButton color={"black"} name={"Add New"} onclick={handleAdd} />
            </Box>
            <AlertDialog
                title={"Are you sure you want to Delete this user?"}
                open={open}
                onClickButtonCancel={() => setOpen(false)}
                onClickButton={() => handledeleteuser()}
            />
            <TableContainer component={Paper}>
                <Toastify />
                <Table sx={{ minWidth: 700, maxWidth: user.role_id === 1 ? '100%' : '96.8%', m: user.role_id === 1 ? 0 : 3 }} aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell align="left">Firstname</StyledTableCell>
                            <StyledTableCell align="left">Lastname</StyledTableCell>
                            <StyledTableCell align="left">Email</StyledTableCell>
                            <StyledTableCell align="left">User Role</StyledTableCell>
                            <StyledTableCell align="left">Type</StyledTableCell>
                            <StyledTableCell align="right">Action</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows?.length === 0 && (
                            <StyledTableRow>
                                <StyledTableCell colSpan={6} align="center">
                                    No Data Found
                                </StyledTableCell>
                            </StyledTableRow>
                        )}
                        {rows?.map((row) => (
                            <StyledTableRow key={row?.id}>
                                <StyledTableCell align="left">{row?.firstname}</StyledTableCell>
                                <StyledTableCell align="left">{row?.lastname}</StyledTableCell>
                                <StyledTableCell align="left">{row?.email}</StyledTableCell>
                                <StyledTableCell align="left">{row?.role_id === 1 ? "Admin" : "User"}</StyledTableCell>
                                <StyledTableCell align="left" style={{ color: row?.deleteFlag && "red" }}>{row?.deleteFlag ? "Deleted" : "Active"}</StyledTableCell>
                                <StyledTableCell align="right">
                                    {!row?.deleteFlag && <><IconButton onClick={() => handleEdit(row?.id)}>
                                        <EditIcon />
                                    </IconButton>
                                        <IconButton style={{ color: 'red' }} onClick={() => handleDelete(row?.id)}>
                                            <DeleteIcon />
                                        </IconButton></>}
                                </StyledTableCell>
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>

            </TableContainer>
            {pages > 1 && (
                <Box sx={{ width: '98%', display: 'flex', justifyContent: 'flex-start', m: 2, ml: 0 }}>
                    <Pagination
                        color="primary"
                        count={pages}
                        page={values?.page}
                        boundaryCount={1}
                        onChange={handlePageChange}
                        siblingCount={0}
                    />
                </Box>
            )}
        </>
    )
}

export default Users