import { put, takeEvery } from "redux-saga/effects";
import { GET_ADD_EDIT_USER, GET_DELETE_USER, GET_LAST_BIDS, GET_MONTHLY_AVG, GET_USER_BY_ID, GET_USERS, GET_WEEKLY_AVG, SET_ADD_EDIT_USER, SET_DELETE_USER, SET_LAST_BIDS, SET_MONTHLY_AVG, SET_USER_BY_ID, SET_WEEKLY_AVG } from "../ConstAction";
import { toast } from "react-toastify";
import AdminApiRequests from "../../services/Admin";
function* getmonthlyavg(data) {

  try {
    let response = yield AdminApiRequests.getmonthlyavg(data.data);

    yield put({
      type: SET_MONTHLY_AVG,
      response,
    });
  } catch (err) { }
}
function* getweeklyavg(data) {
  try {
    let response = yield AdminApiRequests.getweeklybids(data.data);
    yield put({
      type: SET_WEEKLY_AVG,
      response,
    });
  } catch (err) { }
}

function* getlast5bids(data) {

  try {
    let response = yield AdminApiRequests.getlast5bids(data.data);
    yield put({
      type: SET_LAST_BIDS,
      response,
    });
  } catch (err) { }
}
function* getaddedit(data) {

  try {
    let response = yield AdminApiRequests.getaddedit(data.data);
    yield put({
      type: SET_ADD_EDIT_USER,
      response,
    });
    if (response.status) {
      toast.success(response.message)
      setTimeout(() => {
      data.navigate("/users")
        
      }, 2000);
      yield put({
        type: GET_USERS
      })
    }
  } catch (err) { }
}
function* getdeleteuser(data) {

  try {
    let response = yield AdminApiRequests.requestdeleteuser(data.data);
    yield put({
      type: SET_DELETE_USER,
      response,
    });
    if(response.data.status){
      yield put({
        type:GET_USERS
      })
    }
  } catch (err) { }
}
function* getuserbyid(data) {

  try {
    let response = yield AdminApiRequests.requestuserbyid(data.data);
    yield put({
      type: SET_USER_BY_ID,
      response,
    });
  } catch (err) { }
}


function* AdminSaga() {
  yield takeEvery(GET_MONTHLY_AVG, getmonthlyavg);
  yield takeEvery(GET_WEEKLY_AVG, getweeklyavg);
  yield takeEvery(GET_LAST_BIDS, getlast5bids);
  yield takeEvery(GET_ADD_EDIT_USER, getaddedit);
  yield takeEvery(GET_DELETE_USER, getdeleteuser);
  yield takeEvery(GET_USER_BY_ID, getuserbyid);

}
export default AdminSaga;
