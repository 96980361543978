import React from 'react'
import AddEditForm from '../../../Components/AddEditForm'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { useDispatch } from 'react-redux'
import { addeditUser } from '../../../redux/Admin/AdminAction'
import { useNavigate } from 'react-router-dom'

const InitalValues = {
  firstname: "",
  lastname: "",
  email: "",
  role_id:"",
  password: "",
  confirmPassword: ""
}

const Schema = yup.object().shape({
    firstname: yup.string().required('Please enter the first name'),
    lastname: yup.string().required('Please enter the Last name'),
    email: yup.string().email("Please enter a valid email").required('Please enter the email'),
    role_id: yup.string().required('Please select the role'),
    password: yup
      .string()
      .required('Please enter your password.')
      .min(8, 'Password is too short - should be 8 char minimum.'),
    confirmPassword: yup
      .string()
      .required('Confirm your password.')
      .min(8, 'Password is too short - should be 8 char minimum.')
      .oneOf([yup.ref('password'), null], 'Passwords must match'),
  })
const AddUser = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const formik = useFormik({
    initialValues: InitalValues,
    validationSchema: Schema,
    onSubmit: (value) => {
      onSubmit(value)
    },
    enableReinitialize: true,
  })
  const onSubmit = (model) => {
    dispatch(addeditUser(model,navigate))
  }

  return (
    <AddEditForm formik={formik} />
  )
}

export default AddUser