import React from "react";
import { Navigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import { storage } from "../Config/Storage";
const OpenRoutes = ({ children }) => {
  const acc_Token = storage.fetch.auth();
  if (acc_Token) {
    setTimeout(() => {
      toast.error("You are already logged in");
    }, 500);
    return <Navigate to={"/dashboard"} replace />;
  } else {
  }
  return children;
};

export default OpenRoutes;
